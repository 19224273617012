/* styles/styles.css */

  .phone-input-wrapper .react-international-phone-input {
    width: 100%;
  }
  
  .phone-input-wrapper .react-international-phone-input-container {
    width: 100%;
  }

  video::-webkit-media-controls {
    display: none !important;
}

video::-webkit-media-controls-panel {
    display: none !important;
}

video::-webkit-media-controls-start-playback-button {
    display: none !important;
}


video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-panel {
  display: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}


/* Flip cards */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 16px;
  padding: 16px ;
  margin: 60px 0px;

}

.flip-card {
  width: 100%;
  aspect-ratio: 3 / 2;
  perspective: 1000px;
min-height: 500px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.4, 0.2, 0.3, 1);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
}



/* Add this new class for the overlay */
/* .flip-card-front::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); 
  border-radius: 8px;
}  */

/* Make sure the text stays above the overlay */
.flip-card-front h2 {
  position: relative; /* Add this */
  z-index: 1; /* Add this */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 38px;
  font-weight: bold;
}

.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #3D498A;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
}

.flip-card-back h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 5px;

}
.price{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}

.flip-card-back description-all{
  font-size: 10px;
}

.flip-card-back p {
  margin-bottom: 1px;
  padding: 0 16px;
  font-size: 18px;
  margin-top: 10px;
}

.flip-card-back button {
  padding: 10px 20px;
  font-size: 20px;
  background: white !important;
  color: #3D498A;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;
}

.flip-card-back button:hover {
  background: #f0f0f0;
  transform: scale(1.05);
}

@media (max-width: 885px) {
  .flip-card-back h1 {
    font-size: 30px;  
  }
  .grid-container {
    gap: 20px ;
  }
  .flip-card {

  min-height:auto;
  }

  .flip-card-back button {
    padding: 8px 16px;
    font-size: 12px;
  }
}
