@tailwind base;
@tailwind components;
@tailwind utilities;


/* external imports */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face{
  font-family: 'CheapFont';
  src: url('./fonts/CheapSign.ttf') format('truetype');
  font-weight : normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", system-ui;
}

h1, h2, h3, h4, h5, h6,
p, ul, ol, li, blockquote,
pre, hr, address, fieldset, legend,
table, caption, th, td,
form, input, button, textarea, select, label {
  margin: 0;
  padding: 0;
}

img, a {
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5; 
}

::-webkit-scrollbar-thumb {
  background: #3D498A; 
  border-radius: px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3D498A;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.notosans{
  font-family: "Noto Sans", sans-serif;
}

/* custom css */

.sectionlayout{
  max-width: 1640px;
  margin: auto;
  padding: 0 2rem;
}
@media (max-width: 1023px) {
  .sectionlayout{
    padding: 0 1rem;
  }
  
}
@media (max-width: 767px) {
  .sectionlayout{
    padding: 0 1rem;
  }  
}

